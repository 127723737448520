/* @import url('https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css');


.container.gallery-container {
    background-color: #fff;
    color: #35373a;
    min-height: 100vh;
    padding: 30px 50px;
}

.gallery-container h1 {
    text-align: center;
    margin-top: 50px;
    font-family: 'Droid Sans', sans-serif;
    font-weight: bold;
}

.gallery-container p.page-description {
    text-align: center;
    margin: 25px auto;
    font-size: 18px;
    color: #999;
}

.tz-gallery {
    padding: 40px;
}

.tz-gallery .row > div {
    padding: 2px;
}

.tz-gallery .lightbox img {
    width: 100%;
    border-radius: 0;
    position: relative;
}

.tz-gallery .lightbox:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    opacity: 0;
    color: #fff;
    font-size: 26px;
    font-family: 'Glyphicons Halflings';
    content: '\e003';
    pointer-events: none;
    z-index: 9000;
    transition: 0.4s;
}


.tz-gallery .lightbox:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(46, 132, 206, 0.7);
    content: '';
    transition: 0.4s;
}

.tz-gallery .lightbox:hover:after,
.tz-gallery .lightbox:hover:before {
    opacity: 1;
}

.baguetteBox-button {
    background-color: transparent !important;
}

@media(max-width: 768px) {
    body {
        padding: 0;
    }
} */


/* 
#demo {
    height:100%;
    position:relative;
    overflow:hidden;
  }
  
  
  .green{
    background-color:#6fb936;
  }
          .thumb{
              margin-bottom: 30px;
          }
          
          .page-top{
              margin-top:85px;
          }
  
     
  img.zoom {
      width: 100%;
      height: 200px;
      border-radius:5px;
      object-fit:cover;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
  }
          
   
  .transition {
      -webkit-transform: scale(1.2); 
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
  }
      .modal-header {
     
       border-bottom: none;
  }
      .modal-title {
          color:#000;
      }
      .modal-footer{
        display:none;  
      }
   */

   .mainimage .imageContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .mainimage .card {
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
    color: #333333;
    border-radius: 2px;
  }
  .mainimage .card-image {
    background: #ffffff;
    display: block;
    padding-top: 70%;
    position: relative;
    width: 100%;
  }
  .mainimage .card-image img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media only screen and (max-width: 600px) {
    .mainimage .imageContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }